
import { Component, Vue } from 'vue-property-decorator'
import { apiShopShopLevelLists, apiShopShopLevelDel, apiShopShopSetLevel } from '@/api/shop'
import { PageMode } from '@/utils/type'
import { RequestPaging } from '@/utils/util'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
    components: {
        LsDialog,
        LsPagination
    }
})
export default class MemberGrade extends Vue {
    /** S Data **/
    // 分页请求
    pager: RequestPaging = new RequestPaging()
    identity: number = 0
    /** E Data **/

    /** S Methods **/

    //设置用户等级
    setUserLevel(row: any){
        console.log(row)
        apiShopShopSetLevel({
            id: row.sid,
            rank: row.rank
        }).then((res:any)=>{
            this.getUserLevelList();
        })
    }
    // 获取用户等级列表
    getUserLevelList() {
        this.pager
            .request({
                callback: apiShopShopLevelLists,
                params: {
                    sid: this.identity
                }
            }).then((res: any)=>{
                res.lists.unshift({
                    name: '0级',
                    id: 0,
                    rank: 0,
                    discount: '10折'
                })
            })
            .catch(() => {
                this.$message.error('数据请求失败，刷新重载!')
            })
    }
    // 新增用户等级
    onUserLevelAdd() {
        this.$router.push({
            path: '/shop/merchant_level_edit',
            query: {
                mode: PageMode.ADD,
                sid: this.identity as any
            }
        })
    }
    // 编辑用户等级
    onUserLevelEdit(item: any) {
        this.$router.push({
            path: '/shop/merchant_level_edit',
            query: {
                mode: PageMode.EDIT,
                id: item.id,
                level: item.rank,
                sid: this.identity as any
            }
        })
    }

    // 查看用户等级
    onUserLevelSee(item: any) {
        this.$router.push({
            path: '/shop/merchant_level_edit',
            query: {
                mode: PageMode.EDIT,
                id: item.id,
                level: item.rank,
                disabled: 'true',
                sid: this.identity as any
            }
        })
    }

    // 删除用户等级
    onUserLevelDel(item: any) {
        apiShopShopLevelDel({
            id: item.id as number
        })
            .then(() => {
                this.getUserLevelList()
                this.$message.success('删除成功!')
            })
            .catch(() => {
                this.$message.error('删除失败')
            })
    }
    /** E Methods **/
    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query;
        this.identity = query.id;
        if (query.id) {
            this.getUserLevelList()
        }
    }

    /** E Life Cycle **/
}
